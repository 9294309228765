import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import AppendHead from 'react-append-head';
import queryString from 'query-string';
import { Footer } from '@latitude/footer';
import { BrokerHeader } from '@latitude/broker-header';
import footerContent from '../data/json/footer.json';
import LfsHeader from './Header/LfsHeader';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import { saveLoanPartnerName } from '../utils/helpers';
import brokerHeaderData from '../data/pages/brokers/broker-header';
import { PageContext } from '@/context/PageContext';
import Alert from '@/components/lab-components/Alert';
import '../../sass/style.scss';

const TemplateWrapper = props => {
  const [isHidden, setIsHidden] = useState(true);
  const pageData = useContext(PageContext);
  const { header, footer } = pageData?.template || {};
  const { alertData } = pageData?.updatedBody || {};
  const selectedFooter = footer? footer: props?.customFooter? props.customFooter: footerContent;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const queryParams = queryString.parse(location.search);
      /*
       * TO DO
       * 1. remove: line 24 - 25 once About epic is completed
       * 2. remove: delete all state applicable to point 1
       */
      const hasGemFinanceIdParam = location.search.includes(`isgemf`);
      setIsHidden(hasGemFinanceIdParam);
      const param = queryParams.partnername;
      return saveLoanPartnerName(param);
    }
  }, []);

  let metaRobotsValue = null;
  if (props.noIndex && !props.noFollow) {
    metaRobotsValue = 'noindex';
  } else if (!props.noIndex && props.noFollow) {
    metaRobotsValue = 'nofollow';
  } else if (props.noIndex && props.noFollow) {
    metaRobotsValue = 'noindex, nofollow';
  }
  return (
    <React.Fragment>
      <AppendHead>
        <script
          name="datadog-main"
          order="0"
          src="https://www.datadoghq-browser-agent.com/datadog-rum-us.js"
          type="text/javascript"
        />
        <script name="datadog-client" order="1">
          {`
            window.DD_RUM.init({
              clientToken: 'pub5fb466c39da25b58933bac3aeb55bacf',
              applicationId: '005f5c8e-1c16-4666-b410-75d4cfb48965',
            });
            `}
        </script>
      </AppendHead>
      <Helmet>
        <link
          rel="canonical"
          href={props.canonical || `https://www.latitudefinancial.com.au/`}
        />
        <title>
          {props.title || '28° Global Platinum Mastercard | Latitude Financial'}
        </title>
        <meta
          name="description"
          content={
            props.metaDesc ||
            'Our Personal Loan Repayment Calculator helps you get a clear picture of what your repayments would be. Try it now.'
          }
        />
        {metaRobotsValue && <meta name="robots" content={metaRobotsValue} />}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.webp"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.webp"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.webp"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.webp"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.webp"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.webp"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.webp"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.webp"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.webp"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.webp"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.webp"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.webp"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.webp"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* <!-- Google Fonts --> */}
        <link
          href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,500,700|Montserrat:400,800,600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {props.brokerHeader && <BrokerHeader headerData={brokerHeaderData} />}
      {!props.noHeader && !isHidden && (
        <div
          css={`
            header div.top-menu-items div.top-menu-item a {
              padding: 0 8px;
              white-space: nowrap;
            }
          `}
          className="lfs-layout-pageheader"
        >
          <LfsHeader {...header} />
        </div>
      )}
      {!props.noBreadcrumb && <Breadcrumb {...props} />}
      {alertData && (
        <div
          className={`alert-offset ${
            props.hasSecNav ? 'alert-offset--spacer' : ''
          } ${props.hasStickyNav ? 'alert-offset--mobile' : ''}`}
        >
          <Alert {...alertData} />
        </div>
      )}
      {props.children}
      {!props.noFooter && !isHidden && (
        <Footer
          {...selectedFooter}
          cardData={
            selectedFooter?.cardData?.text
              ? {
                  text: selectedFooter?.cardData?.text,
                  href: selectedFooter?.cardData?.href,
                  img: selectedFooter?.cardData?.img
                }
              : undefined
          }
        />
      )}
    </React.Fragment>
  );
};

export default TemplateWrapper;